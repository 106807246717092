import React, { useEffect } from 'react';
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { webAnalytics as wa} from "@principal/web-analytics";

import flow from '@principal/business-needs-assessment-tool-helpers';

import Advisor from './advisor';
import AreaOfConcern from './areas-of-concern';
import Checkpoint from './checkpoint';
import LoadAssessment from './loader';
import Question from './question';
import Results from './results';
import Summary from './question-summary';
import TestHelper from './test-helper';
import { newDocId } from '../../util';
import styles from '../../css/assessment.module.css';
import helpers from './helpers';
import request from './request';

const AssessmentState = {
    Initial: () => ({
        syncState: {
            firstLoad: true,
            writeCount: 0,
            lastNotificationSentAtWriteCount: 0,
            modified: false,
            id: null,
            saveError: null,
            loadError: null
        },
        assessmentId: null,
        topics: {},
        answers: flow.answers(),
        advisor: {
            hasAdvisor: false,
            resultEmails: Object.fromEntries(
                flow.CATEGORIES.map(cat => [cat.id, null])
            )
        },
        timestamps: Object.fromEntries(
            flow.CATEGORIES.map(cat => [cat.id, []])
        ),
        createdAt: Date.now()
    }),
    Actions: update => ({
        toggleCompletion: (topic, concern) =>
            update(assessmentState => {
                assessmentState.topics = helpers.toggleCompletion(
                    assessmentState.topics,
                    topic,
                    concern
                );
                return helpers.markModified(assessmentState);
            }),
        setAnswer: (question, answer) =>
            update(assessmentState => {
                assessmentState.answers = flow.setAnswer(
                    flow.QUESTIONS,
                    assessmentState.answers,
                    question,
                    answer
                );
                return helpers.markModified(assessmentState);
            }),
        toggleCheckbox: (question, answer) =>
            update(assessmentState => {
                assessmentState.answers = flow.toggleCheckbox(
                    flow.QUESTIONS,
                    assessmentState.answers,
                    question,
                    answer
                );
                return helpers.markModified(assessmentState);
            }),
        sendResultsEmailToAdvisor: (categoryId, assessmentId) => {
            update(assessmentState => {
                assessmentState.advisor.resultEmails[categoryId] =
                    'task_pending';
                console.log(
                    'task pending',
                    assessmentState.advisor.resultEmails[categoryId]
                );
                return helpers.markModified(assessmentState);
            });

            fetch(
                `/needs/api/v1/tasks/create-email-advisor-results-task/${assessmentId}/${categoryId}`,
                {
                    method: 'POST',
                    mode: 'cors'
                }
            ).catch(error => {
                console.error(error);
                update(assessmentState => {
                    assessmentState.advisor.resultEmails[categoryId] =
                        'error_task_creation';
                    console.log(
                        'error task creation',
                        assessmentState.advisor.resultEmails[categoryId]
                    );
                    return helpers.markModified(assessmentState);
                });
            });
        },
        sendTaskToSalesforce: (categoryId, assessmentId) => {
            update(assessmentState => {
                assessmentState.syncState.lastNotificationSentAtWriteCount =
                    assessmentState.syncState.writeCount + 1;
                return helpers.markModified(assessmentState);
            });

            fetch(
                `/needs/api/v1/tasks/create-sf-request-task/${assessmentId}/${categoryId}`,
                {
                    method: 'POST',
                    mode: 'cors'
                }
            ).catch(error => {
                console.error(error);
            });
        },
        setAssessmentId: assessmentId =>
            update(assessmentState => ({
                ...assessmentState,
                assessmentId,
                syncState: {
                    ...assessmentState.syncState,
                    id: assessmentId,
                    firstLoad: false
                }
            })),
        updateTimestamps: categoryId =>
            update(assessmentState => {
                assessmentState.timestamps[categoryId].push(
                    new Date(Date.now()).toString()
                );
                console.log(`timestamp updated for ${categoryId}`);
                return helpers.markModified(assessmentState);
            }),
        saveAssessmentState: assessmentState =>
            request.saveAssessmentState(update, assessmentState),
        loadAssessmentState: (assessmentId, redirector) =>
            request.loadAssessmentState(update, assessmentId, redirector),
        setAdvisorInfo: (name, email, isGuided) => {
            update(assessmentState => {
                assessmentState.advisor.hasAdvisor = true;
                assessmentState.advisor.name = name;
                assessmentState.advisor.email = email;
                assessmentState.advisor.isGuided = isGuided;
                const logMessage = `update advisor info for ${
                    assessmentState.syncState.id
                } - ${JSON.stringify(assessmentState.advisor)}`;
                fetch(`/needs/api/v1/tasks/log`, {
                    method: 'POST',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify({
                        logMessage
                    })
                });
                return helpers.markModified(assessmentState);
            });
        }
    })
};

const Category = ({
    assessmentId,
    category,
    answers,
    setAnswer,
    toggleCheckbox,
    advisor,
    saveError,
    showContactFormModal,
    assessmentActions,
    assessmentState
}) => {
    const location = useLocation();

    const { profileQuestions, categoryQuestions } = helpers.getQuestions(
        category.id,
        answers
    );

    const unansweredQuestions = profileQuestions
        .unanswered()
        .concat(categoryQuestions.unanswered());

    const answeredProfileQuestions = profileQuestions.answered();
    const answeredCategoryQuestions = categoryQuestions.answered();

    const allQuestions = profileQuestions.concat(categoryQuestions);

    // TODO: Should this be unansweredQuestions rather than allQuestions.
    // Currently, this forces users to the very first question if they've not
    // answered it. However, I think we should be using unansweredQuestions.
    // That would instead force the user to the next unanswered-question.
    const firstQuestionUrl = helpers.questionUrlBuilder(
        category.id,
        allQuestions[0].id
    );

    if (
        location.pathname !== firstQuestionUrl &&
        answers[allQuestions[0].id] === undefined &&
        !location.pathname.includes('app-validation-test')
    ) {
        return (
            <Redirect
                to={helpers.appendAssessmentId(firstQuestionUrl, assessmentId)}
            />
        );
    }

    return (
        <div className={styles.category}>
            <Switch>
                <Route
                    exact
                    path={helpers.questionUrlBuilder(
                        category.id,
                        'app-validation-test'
                    )}
                >
                    <TestHelper
                        allQuestions={allQuestions}
                        assessmentId={assessmentId}
                        category={category}
                        answers={answers}
                        setAnswer={setAnswer}
                        toggleCheckbox={toggleCheckbox}
                        advisor={advisor}
                        showContactFormModal={showContactFormModal}
                    />
                </Route>
                {allQuestions.map((question, index) => (
                    <Route
                        key={question.id}
                        path={helpers.questionUrlBuilder(
                            category.id,
                            question.id
                        )}
                    >
                        <Question
                            question={question}
                            nextUrl={helpers.appendAssessmentId(
                                helpers.nextQuestionHelper(
                                    category.id,
                                    allQuestions[index + 1]
                                ),
                                assessmentId
                            )}
                            testMode={false}
                            answerAction={
                                question.type === flow.QUESTION_TYPE.CHECKBOX
                                    ? toggleCheckbox
                                    : setAnswer
                            }
                            userAnswer={answers[question.id]}
                            questionIndex={index}
                            questionCount={allQuestions.length}
                        />
                    </Route>
                ))}
                <Route exact path={helpers.summaryUrlBuilder(category.id)}>
                    <Summary
                        questionBlock={[
                            {
                                title: 'Business profile',
                                questions: answeredProfileQuestions,
                                editUrl: helpers.appendAssessmentId(
                                    helpers.nextQuestionHelper(
                                        category.id,
                                        answeredProfileQuestions[0] || null
                                    ),
                                    assessmentId
                                )
                            }
                        ]}
                        answers={answers}
                        nextUrl={helpers.appendAssessmentId(
                            helpers.nextQuestionHelper(
                                category.id,
                                categoryQuestions[0] || null
                            ),
                            assessmentId
                        )}
                        questionIndex={answeredProfileQuestions.length}
                        questionCount={allQuestions.length}
                    />
                </Route>
                <Route exact path={helpers.resultsUrlBuilder(category.id)}>
                    <Results
                        assessmentId={assessmentId}
                        categoryId={category.id}
                        editUrl={helpers.appendAssessmentId(
                            answeredProfileQuestions.length > 0
                                ? helpers.summaryUrlBuilder(category.id)
                                : firstQuestionUrl,
                            assessmentId
                        )}
                        answers={answers}
                        advisor={advisor}
                        saveError={saveError}
                        showContactFormModal={showContactFormModal}
                        assessmentActions={assessmentActions}
                        assessmentState={assessmentState}
                    />
                </Route>
                <Route path={helpers.categoryUrlBuilder(category.id)}>
                    <Redirect
                        to={helpers.appendAssessmentId(
                            answeredProfileQuestions.length > 0
                                ? helpers.summaryUrlBuilder(category.id)
                                : firstQuestionUrl,
                            assessmentId
                        )}
                    />
                </Route>
            </Switch>
        </div>
    );
};

Category.propTypes = {
    category: PropTypes.shape({
        buttonText: PropTypes.string,
        description: PropTypes.string,
        etc: PropTypes.number,
        id: PropTypes.string,
        title: PropTypes.string,
        topics: PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    answers: PropTypes.objectOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.objectOf(PropTypes.bool)
        ])
    ).isRequired,
    setAnswer: PropTypes.func.isRequired,
    toggleCheckbox: PropTypes.func.isRequired,
    assessmentId: PropTypes.string
};

const CategoryRoutes = ({
    assessmentId,
    answers,
    setAnswer,
    toggleCheckbox,
    advisor,
    saveError,
    showContactFormModal,
    assessmentActions,
    assessmentState
}) =>
    flow.CATEGORIES.map(category => (
        <Route key={category.id} path={helpers.categoryUrlBuilder(category.id)}>
            <Category
                assessmentId={assessmentId}
                category={category}
                answers={answers}
                setAnswer={setAnswer}
                toggleCheckbox={toggleCheckbox}
                advisor={advisor}
                saveError={saveError}
                showContactFormModal={showContactFormModal}
                assessmentActions={assessmentActions}
                assessmentState={assessmentState}
            />
        </Route>
    ));

const TopicPageRoutes = ({ assessmentState, assessmentActions }) =>
    flow.TOPIC_PAGES.map((topicPage, index) => (
        <Route
            key={topicPage.url}
            exact
            path={helpers.topicUrlBuilder(topicPage.url)}
        >
            <AreaOfConcern
                preTitle={topicPage.preTitle}
                title={topicPage.statement}
                topicName="Select all that apply"
                completions={topicPage.completions}
                nextPage={helpers.appendAssessmentId(
                    helpers.nextPageHelper(flow.TOPIC_PAGES[index + 1]),
                    assessmentState.syncState.id
                )}
                concernToggler={helpers.concernToggler(
                    assessmentActions,
                    topicPage.url
                )}
                concernStateChecker={helpers.concernStateChecker(
                    assessmentState,
                    topicPage.url
                )}
            />
        </Route>
    ));

const LoadError = ({ syncState }) => (
    <div className={styles.loading}>
        <h1 className={styles.loadingTitle} id="error-loading">
            Error loading the requested assessment results.
        </h1>
        <div className={styles.loadingMessage}>
            <span className={styles.loadingErrorLabel}>Assessment id:</span>
            {syncState.id}
        </div>
        <div className={styles.loadingMessage}>
            <span className={styles.loadingErrorLabel}>Error message:</span>
            {syncState.loadError}
        </div>
    </div>
);

const Assessment = ({
    assessmentState,
    assessmentActions,
    showContactFormModal
}) => {
    const location = useLocation();
    const history = useHistory();

    const assessmentId = assessmentState.syncState.id;
    const assessmentIdParam = new URLSearchParams(location.search).get(
        'assessmentId'
    );

    // Generate new assessmentId, load existing assessment, or save progress.
    useEffect(() => {
        if (!assessmentIdParam && assessmentState.syncState.firstLoad) {
            assessmentActions.setAssessmentId(newDocId());
            if (
                location.pathname !== '/needs/assessment/advisor' &&
                location.pathname !== '/needs/assessment/advisor/'
            ) {
                history.replace(
                    helpers.topicUrlBuilder(flow.TOPIC_PAGES[0].url)
                );
                 wa.trackPageView({
                     content: {
                         businessline: 'benefits-and-protection',
                         intendedaudience: 'financial-professional, individual',
                         purpose: 'engagement'
                     },
                     page: {
                         author: 'Team43',
                         compliancedate: '',
                         documenttype: 'webapp',
                         language: 'english'
                     }
                 });
            }
        } else if (assessmentIdParam && assessmentIdParam !== assessmentId) {
            history.replace(
                helpers.appendAssessmentId(
                    helpers.loadUrlBuilder(
                        assessmentIdParam,
                        location.pathname
                    ),
                    assessmentId
                ));
                wa.trackPageView({
                    content: {
                        businessline: 'benefits-and-protection',
                        intendedaudience: 'financial-professional, individual',
                        purpose: 'engagement'
                    },
                    page: {
                        author: 'Team43',
                        compliancedate: '',
                        documenttype: 'webapp',
                        language: 'english'
                    }
                });
        } else if (helpers.isModified(assessmentState)) {
            assessmentActions.saveAssessmentState(assessmentState);
        }
    }, [assessmentState.syncState]);

    const categoryStates = Object.fromEntries(
        Object.values(flow.CATEGORIES).map(category => [
            category.id,
            flow.getUnansweredQuestions(
                flow.getQuestionsForCategory(
                    category.id,
                    flow.QUESTIONS,
                    assessmentState.answers
                ),
                assessmentState.answers
            ).length === 0
        ])
    );
    const ui_host = process.env.UI_HOST
        ? process.env.UI_HOST
        : window.location.href.substring(
              0,
              window.location.href.indexOf(window.location.pathname)
          );

    function validateUIHost(uiHost) {
        if (uiHost === 'https://life.employers.dev.principal.com') {
            return 'https://life.employers.dev.principal.com';
        };
        if (uiHost === 'https://life.employers.staging.principal.com') {
            return 'https://life.employers.staging.principal.com';
        };
        if (uiHost === 'https://life.employers.principal.com') {
            return 'https://life.employers.principal.com';
        };
        return '';
    }

    return (
        <Switch>
            <Route
                path={[
                    '/needs/assessment/advisor',
                    '/needs/assessment/advisor/'
                ]}
            >
                <>
                    <Advisor.MainForm
                        assessmentActions={assessmentActions}
                        advisor={assessmentState.advisor}
                        assessmentId={assessmentId}
                        saveError={assessmentState.syncState.saveError}
                    />
                </>
            </Route>
            <Route path="/needs/assessment/info">
                <Advisor.ClientLanding assessmentId={assessmentId} />
            </Route>
            {TopicPageRoutes({ assessmentState, assessmentActions })}
            {CategoryRoutes({
                assessmentId,
                answers: assessmentState.answers,
                setAnswer: assessmentActions.setAnswer,
                toggleCheckbox: assessmentActions.toggleCheckbox,
                advisor: assessmentState.advisor,
                saveError: assessmentState.syncState.saveError,
                showContactFormModal,
                assessmentActions,
                assessmentState
            })}
            <Route path={helpers.urlBuilder('checkpoint')}>
                <Checkpoint.Component
                    comebackUrl={
                        assessmentId
                            ? `${validateUIHost(ui_host)}${helpers.appendAssessmentId(
                                  helpers.urlBuilder('checkpoint'),
                                  assessmentId
                              )}`
                            : ''
                    }
                    urlBuilder={categoryId =>
                        helpers.appendAssessmentId(
                            helpers.categoryUrlBuilder(categoryId),
                            assessmentId
                        )
                    }
                    userChoices={assessmentState.topics}
                    categoryStates={categoryStates}
                    showContactFormModal={showContactFormModal}
                    advisor={assessmentState.advisor}
                    assessmentId={assessmentId}
                    saveError={assessmentState.syncState.saveError}
                    assessmentActions={assessmentActions}
                />
            </Route>
            <Route path={helpers.loadUrlBuilder(':assessmentId')}>
                <LoadAssessment assessmentActions={assessmentActions} />
            </Route>
            <Route path={helpers.urlBuilder('loading-error')}>
                <LoadError syncState={assessmentState.syncState} />
            </Route>
            <Route path="/needs">
                <Redirect
                    to={helpers.topicUrlBuilder(flow.TOPIC_PAGES[0].url)}
                />
            </Route>
        </Switch>
    );
};

Assessment.propTypes = {
    assessmentState: PropTypes.shape({
        topics: PropTypes.objectOf(PropTypes.objectOf(PropTypes.bool))
            .isRequired
    }).isRequired,
    assessmentActions: PropTypes.objectOf(PropTypes.func).isRequired
};

export default {
    Component: Assessment,
    State: AssessmentState
};
